import http from "./http";

const university = {
  list: (param) => http.get("/customer/university/list", { params: param }),
  getOne: (param) => http.get(`/customer/university/view?id=${param}`),

};

export default university;

 