import React, { Suspense } from "react";
import "./App.css";
import "./About.css";
import "./home.css"
import { BrowserRouter, Route, Routes} from "react-router-dom";
import FrontRoutes from "./Routes";
const Layout = React.lazy(() => import("../src/Layouts"));
const Home = React.lazy(() => import("./Frontend/Home"));


function App() {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="vh-100 d-flex align-items-center justify-content-center">
            Loading...
          </div>
        }
      >
        <Routes>
          <Route path="*" element={<Layout />}>
            {FrontRoutes.map((route, index) => (
              <Route
                key={`user-key-${index}`}
                path={route.path}
                element={<route.element />}
              />
            ))}
          </Route>
          {/* <Route path="/" element={<Home />}/> */}

        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
