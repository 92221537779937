import React, { useState, useEffect } from "react";
import { Col, Row, Spinner } from "reactstrap";
import course from "../../services/course";

const Index = () => {
  const [courses, setCourses] = useState([]);
  const [loader, setLoader] = useState(false);

  const getCourses = () => {
    setLoader(true);
    course
      .list()
      .then((res) => {
        if (res.data.status) {
          setCourses(res?.data?.data?.courses);
          // if (!searchValue) {
          //   setPage(res.data.data.current_page);
          // }
          // setTotalPages(res.data.data.total / res.data.data.per_page);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };
  useEffect(() => {
    getCourses();
  }, []);
  return (
    <div>
      {" "}
      <div className="rbt-section-gapBottom masonary-wrapper-activation">
        <div className="container">
          {/* Start Card Area */}
          <div className="row row--15">
            <div className="col-lg-12">
              <div className="mesonry-list grid-metro2">
                <div className="resizer" />
                {
                  loader ? <div className="d-flex justify-content-center align-items-center"><Spinner  style={{
                    height: '3rem',
                    width: '3rem',
                    color:"#AF97E8"
                  }} /></div>
                  :
                  <Row>
                  {courses.length > 0 &&
                    courses.map((course, i) => (
                      <Col l key={`courses-key${i}`} md={6}>
                        {" "}
                        <div className="maso-item cat--1">
                          <div className="rbt-card variation-01 rbt-hover card-list-2">
                            <div className="rbt-card-img">
                              <a href="course-details.html">
                                <img src={course?.image} alt="Card image" />
                              </a>
                            </div>
                            <div className="rbt-card-body">
                              <div className="rbt-card-top">
                                <div className="rbt-review">
                                  <div className="rating">
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                    <i class="fa-solid fa-star"></i>
                                  </div>
                                  <span className="rating-count">
                                    {" "}
                                    (15 Reviews)
                                  </span>
                                </div>
                                <div className="rbt-bookmark-btn">
                                  <a
                                    className="rbt-round-btn"
                                    title="Bookmark"
                                    href="#"
                                  >
                                    <i className="feather-bookmark" />
                                  </a>
                                </div>
                              </div>
                              <h4 className="rbt-card-title">
                                <a href="course-details.html">{course?.name}</a>
                              </h4>
                              <ul className="rbt-meta">
                                <li>
                                  <i className="feather-book" />
                                  12 Lessons
                                </li>
                                <li>
                                  <i className="feather-users" />
                                  50 Students
                                </li>
                              </ul>
                              <p className="rbt-card-text">
                              {course?.description.length > 100 ? course?.description.substring(0, 100) + "..." : course?.description}
                              </p>

                              <div className="rbt-card-bottom">
                                <div className="rbt-price">
                                  {/* <span className="current-price">$60</span>
        <span className="off-price">$120</span> */}
                                </div>
                                <a
                                  className="rbt-btn-link"
                                  href="course-details.html"
                                >
                                  Learn More
                                  <i className="feather-arrow-right" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                </Row>
                }
              </div>
              {/* End Card Area */}
            </div>
          </div>
        </div>
      </div>
      <div className="rbt-separator-mid">
        <div className="container">
          <hr className="rbt-separator m-0" />
        </div>
      </div>
    </div>
  );
};

export default Index;
