import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Col, Row } from "reactstrap";
import banner from "../../services/banner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Index = () => {
  const [banners, setBanners] = useState([]);
  const [loader, setLoader] = useState(false);

  const [counts, setCounts] = useState({
    learners: 0,
    courses: 0,
    certifiedStudents: 0,
  });

  const Counter = ({ count, duration = 2000 }) => {
    const [number, setNumber] = useState(0);

    useEffect(() => {
      let start = 0;

      const increment = (count / duration) * 10;

      const timer = setInterval(() => {
        start += increment;
        if (start < count) {
          setNumber(Math.ceil(start));
        } else {
          setNumber(count);
          clearInterval(timer);
        }
      }, 10);

      return () => clearInterval(timer);
    }, [count, duration]);

    return <span className="odometer">{number}</span>;
  };

  var settings = {
    dots: true,
    // infinite: true,
    autoplay: true,
    pauseOnHover: true,
    draggable: true,
    arrows: true,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getBanners = () => {
    setLoader(true);
    banner
      .list()
      .then((res) => {
        if (res.data.status) {
          setBanners(res?.data?.data);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log("error", error);
      });
  };
  useEffect(() => {
    getBanners();
  }, []);
  return (
    <div>
      {" "}
      <Carousel
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        autoPlay
        infiniteLoop={true}
      >
        {banners?.banners?.map((ele, i) => (
          <>
            <Row style={{ maxHeight: "400px" }}>
              <Col md={12} key={i}>
                <img src={ele?.image} />
              </Col>
            </Row>
          </>
        ))}
      </Carousel>
      <div class="rbt-about-area about-style-1 bg-color-white rbt-section-gap">
        <div class="container">
          <div class="row g-5 align-items-start">
            <div class="col-lg-6">
              <div class="content">
                <h2
                  class="title mb--0 sal-animate university_text"
                  data-sal="slide-up"
                  data-sal-duration="700"
                >About Dr. J.L. Chaudhary Institute of Technology and Management
                </h2>
              </div>
            </div>
            <div
              class="col-lg-6 sal-animate"
              data-sal="slide-up"
              data-sal-duration="700"
            >
              <p class="mb--40 mb_sm--20">
              Explore the Dr. J.L. Chaudhary Institute of Technology and Management: a hub of innovation and creativity, designed to prepare you for your future career. Here, we foster a supportive environment that nurtures your aspirations and helps you achieve your full potential.
              </p>
              <div class="readmore-btn">
                <a class="rbt-moderbt-btn" href="/about-us">
                  <span
                    class="moderbt-btn-text"
                    style={{
                      fontSize: "18px",
                      fontWeight: "400",
                      textDecoration: "none",
                    }}
                  >
                    Institute Overview
                    <i className="fa-solid fa-arrow-right"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="" style={{ marginBottom: "100px" }}>
        <div className="container">
          <div className="row g-5">
            {/* Start Single Counter */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rbt-counterup style-3">
                <div className="inner">
                  <div className="content">
                    <h2 className="counter">
                      <Counter count={500} />
                    </h2>
                    <span className="subtitle">Learners &amp; counting</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Another Counter Example */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rbt-counterup style-3">
                <div className="inner">
                  <div className="content">
                    <h2 className="counter">
                      <Counter count={800} />
                    </h2>
                    <span className="subtitle">Courses &amp; Video</span>
                  </div>
                </div>
              </div>
            </div>

            {/* And another Counter Example */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rbt-counterup style-3">
                <div className="inner">
                  <div className="content">
                    <h2 className="counter">
                      <Counter count={1000} />
                    </h2>
                    <span className="subtitle">Certified Students</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="rbt-splite-style">
          <div className="split-wrapper">
            <div className="row g-0 align-items-center">
              <div className="col-lg-12 col-xl-6 col-12">
                <div className="thumbnail image-left-content">
                  <img src="./assets/images/split-01.jpg" alt="split Images" />
                </div>
              </div>
              <div className="col-lg-12 col-xl-6 col-12">
                <div className="split-inner">
                  <div className="shape">
                    <img src="assets/images/school.png" alt="Shape Images" />
                  </div>
                  <h4
                    className="title sal-animate"
                    data-sal="slide-up"
                    data-sal-duration={400}
                    data-sal-delay={200}
                  >
                    Campus Information
                  </h4>
                  <p
                    className="description sal-animate"
                    data-sal="slide-up"
                    data-sal-duration={400}
                    data-sal-delay={300}
                  >
                   At DRJLCITM, we equip you for career success with a nurturing, innovative, and professional setting where you can acquire hands-on skills, and expand your industry network
                  </p>
                  <ul
                    className="split-list sal-animate"
                    data-sal="slide-up"
                    data-sal-duration={400}
                    data-sal-delay={350}
                  >
                    <li className="common_text">
                      - Doug DeMarco, Design Prototyping Manager
                    </li>
                    <li className="common_text">
                      - 108 million paying subscribers
                    </li>
                    <li className="common_text">
                      - Over 1.7 billion hours of music played monthly
                    </li>
                    <li className="common_text">
                      - 4,000+ employees working across 16 offices
                    </li>
                  </ul>
                  <div class="readmore-btn">
                    <a class="rbt-moderbt-btn" href="/contact-us">
                      <span
                        class="moderbt-btn-text"
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          textDecoration: "none",
                        }}
                      >
                        Schedule a tour
                        <i className="fa-solid fa-arrow-right"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rbt-about-area about-style-1 bg-color-white rbt-section-gap">
        <div className="wrapper">
          <div className="container">
            <div className="row g-5 align-items-start">
              <div className="col-lg-4">
                <div className="content">
                  <h2
                    className="title sal-animate"
                    style={{ fontSize: "45px", fontWeight: "600" }}
                    data-sal="slide-up"
                    data-sal-duration={700}
                  >
                    Graduate Programs.
                  </h2>
                </div>
              </div>
              <div
                className="col-lg-4 sal-animate"
                data-sal="slide-up"
                data-sal-duration={700}
              >
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam
                  inventore praesentium alias incidunt! Veritatis,
                  necessitatibus fuga dolore tenetur, beatae suscipit fugit est
                  ea perspiciatis quo provident nisi dolor similique architecto
                  nihil.
                </p>
              </div>
              <div
                className="col-lg-4 sal-animate"
                data-sal="slide-up"
                data-sal-duration={700}
              >
                <p>
                  Graduate Programs dolor sit amet consectetur adipisicing elit.
                  Nam inventore praesentium alias incidunt! Veritatis,
                  necessitatibus fuga dolore tenetur, beatae suscipit fugit est
                  ea perspiciatis quo provident nisi dolor similique architecto
                  nihil.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper mt--60">
          <div className="container">
            <div className="row g-5">
              {banners?.universities?.map((uni, i) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 sal-animate"
                  data-sal-delay={150}
                  data-sal="slide-up"
                  data-sal-duration={800}
                >
                  <div className="rbt-card variation-03 rbt-hover">
                    <div className="rbt-card-img">
                      <a className="thumbnail-link" href="course-details.html">
                        <img src={uni?.logo} alt="Card image" />
                        <span className="rbt-btn btn-white icon-hover">
                          <span className="btn-text">Read More</span>
                          <span className="btn-icon">
                            <i className="feather-arrow-right" />
                          </span>
                        </span>
                      </a>
                    </div>
                    <div className="rbt-card-body">
                      <h5 className="rbt-card-title">
                        <a href="course-details.html">{uni?.name}</a>
                      </h5>
                      <div className="rbt-card-bottom">
                        <a
                          className="transparent-button"
                          href="course-details.html"
                        >
                          <i>
                            <svg
                              width={17}
                              height={12}
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g
                                stroke="#27374D"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <path d="M10.614 0l5.629 5.629-5.63 5.629" />
                                <path
                                  strokeLinecap="square"
                                  d="M.663 5.572h14.594"
                                />
                              </g>
                            </svg>
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/* End Single Card  */}
            </div>
          </div>
        </div>
        <div className="rbt-service-area bg-gradient-2 rbt-section-gapTop">
          <div className="container">
            <div className="row mb--60">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle bg-pink-opacity">
                    ONLINE COURSE
                  </span>
                  <h2 className="title university_text">Our Online Course</h2>
                </div>
              </div>
            </div>
            <div className="swiper service-item-3-activation  rbt-arrow-between gutter-swiper-30">
              <div className="swiper-wrapper">
                {/* Start Single Card  */}
                <Row>
                  <Slider {...settings}>
                    {banners?.courses?.map((course, i) => (
                      <Col md={6} key={`course-key${i}`}>
                        <div className="swiper-slide">
                          <div className="single-slide">
                            <div className="rbt-service rbt-service-2  bg-no-shadow card-bg-1">
                              <div className="inner">
                                <div className="content">
                                  <h4 className="title common_text3">
                                    <a href="#">{course?.name}</a>
                                  </h4>
                                  <p>
                                    {" "}
                                    {course?.description.length > 20
                                      ? course?.description.substring(0, 20) +
                                        "..."
                                      : course?.description}
                                  </p>
                                  <a className="transparent-button" href="#">
                                    Learn More
                                    <i>
                                      <svg
                                        width={17}
                                        height={12}
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          stroke="#27374D"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <path d="M10.614 0l5.629 5.629-5.63 5.629" />
                                          <path
                                            strokeLinecap="square"
                                            d="M.663 5.572h14.594"
                                          />
                                        </g>
                                      </svg>
                                    </i>
                                  </a>
                                </div>
                                <div className="thumbnail">
                                  <img
                                    src={course?.image}
                                    alt="Education Images"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Slider>
                </Row>
              </div>
            </div>
          </div>
        </div>

   <div className="rbt-callto-action-area rbt-section-gapTop">
  <div className="rbt-callto-action rbt-cta-default style-4 bg-gradient-6 mt--75">
    <div className="container">
      <div className="row align-items-center content-wrapper row--30 mt_dec--30 position-relative">
        <div className="col-lg-8 mt--30 offset-lg-3">
          <div className="inner">
            <div className="content text-left">
              <h2 className="title common_text4">Ready to start creating a Educational Website?</h2>
              <div className="call-to-btn text-start mt--30">
                <a className="rbt-btn btn-gradient hover-icon-reverse radius-round" href="#">
                  <span className="icon-reverse-wrapper">
                    <span className="btn-text">Know more</span>
                    <span className="btn-icon"><i className="feather-arrow-right" /></span>
                    <span className="btn-icon"><i className="feather-arrow-right" /></span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="thumbnail">
          <img className="w-100" src="./assets/images/cta.png" alt="Shape Images" />
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
    </div>
  );
};

export default Index;
