
import Course from "../Frontend/Course";
import Universities from "../Frontend/Universities";
import About from "../Frontend/About"
import Home from "../Frontend/Home"
import BecomeTeacher from "../Frontend/BecomeTeacher"



const Routes = [
  {
    path: "courselist",
    name: "Course List",
    element: Course,
    authentication: false,
  },
  {
    path: "",
    name: "Home",
    element: Home,
    authentication: false,
  },
  {
    path: "universities",
    name: "Universities",
    element: Universities,
    authentication: false,
  },
  {
    path: "about-us",
    name: "About",
    element: About,
    authentication: false,
  },
  {
    path: "apply-now",
    name: "Apply",
    element: About,
    authentication: false,
  },
  {
    path: "become-a-teacher",
    name: "Become a Teacher",
    element: BecomeTeacher,
    authentication: false,
  },
  
];

export default Routes;
